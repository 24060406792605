import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreatePost.css";

function CreatePost() {
  const [caption, setCaption] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Set loading to true
    setIsLoading(true);

    const cloudName = "db0wqr9uf";
    const uploadPreset = "dpc@img";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", uploadPreset);

    try {
      const cloudinaryResponse = await axios.post(cloudinaryUrl, formData);
      console.log("Cloudinary Response:", cloudinaryResponse);

      const imageUrl = cloudinaryResponse.data.secure_url;

      const apiEndpoint = "https://dpc-backend.onrender.com/post/create";
      const postData = {
        caption: caption,
        imageUrl: imageUrl,
      };
      console.log("Post Data:", postData);

      const apiResponse = await axios.post(apiEndpoint, postData);
      console.log("API Response:", apiResponse.data);

      toast.success("Post created successfully");
      setCaption("");
      setImage(null);
      setIsLoading(false);
    } catch (error) {
      toast.error("Error creating post");
      setIsLoading(false);
      console.error("Error creating post:", error);
    }
  };

  return (
    <section>
      <div className="gallery-div">
        <h1>Create a New Post</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="caption">Caption:</label>
            <input
              type="text"
              id="caption"
              value={caption}
              onChange={handleCaptionChange}
              required
            />
          </div>
          <div>
            <label htmlFor="image">Image:</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          </div>
          <div>
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default CreatePost;
