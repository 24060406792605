import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./NewsList.css";

const NewsList = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://dpc-backend.onrender.com/notification/all"
        );
        setNewsList(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  const handleDelete = async (newsId) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-modal">
              <h1>Confirm Deletion</h1>
              <p>Do you want to delete this news?</p>
              <button
                onClick={async () => {
                  await axios.delete(
                    `https://dpc-backend.onrender.com/notification/delete/${newsId}`
                  );
                  setNewsList((prevNewsList) =>
                    prevNewsList.filter((news) => news._id !== newsId)
                  );
                  onClose();
                }}>
                Yes
              </button>
              <button onClick={onClose}>Cancel</button>
            </div>
          );
        },
      });
    } catch (error) {
      console.error("Error deleting news:", error);
    }
  };

  return (
    <section>
      <h2>News List</h2>
      <div className="news-div">
        <table>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>News</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {newsList.map((news, index) => (
              <tr key={news._id}>
                <td>{index + 1}</td>
                <td>{news.news}</td>
                <td>
                  <button onClick={() => handleDelete(news._id)}>
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Floating button for creating a new news */}
      <Link to="/create-news" className="floating-button">
        <FaPlus />
      </Link>
    </section>
  );
};

export default NewsList;
