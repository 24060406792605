import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/NavbarComponent/Navbar";
import Gallery from "./components/Pages/GalleryPage/Gallery";
import Event from "./components/Pages/EventPage/EventList";
import CreatePost from "./components/Pages/GalleryPage/CreatePost";
import StaffList from "./components/Pages/StaffPages/StaffList";
import CreateEvent from "./components/Pages/EventPage/CreateEvent";
import CreateStaff from "./components/Pages/StaffPages/CreateStaff";
import Notices from "./components/Pages/NoticePages/Notices";
import DepartmentList from "./components/Pages/DepartmentPages/DepartmentList";
import CreateDepartment from "./components/Pages/DepartmentPages/CreateDepartment";
import Home from "./components/Pages/HomePage/Home";
import Course from "./components/Pages/CoursePages/Course";
import CreateCourse from "./components/Pages/CoursePages/CreateCourse";
import Blog from "./components/Pages/BlogPages/Blog";
import Login from "./components/Pages/Login/Login";
import Signup from "./components/Pages/Login/Signup";
import NewsList from "./components/Pages/NotificationPages/NewsList";
import CreateNews from "./components/Pages/NotificationPages/CreateNews";
import CreateBlog from "./components/Pages/BlogPages/CreateBlog";
import CreateNotice from "./components/Pages/NoticePages/CreateNotice";
import Upload from "./components/Pages/UploadData/Upload";
import PrivateRoute from "./components/Util/PrivateRoute";
import DepartmentDetails from "./components/Pages/DepartmentPages/DepartmentDetails";
import CreateUpload from "./components/Pages/UploadData/CreateUpload";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/event" element={<Event />} />
            <Route path="/create-event" element={<CreateEvent />} />
            <Route path="/create-post" element={<CreatePost />} />
            <Route path="/staff-list" element={<StaffList />} />
            <Route path="/create-staff" element={<CreateStaff />} />
            <Route path="/notice" element={<Notices />} />
            <Route path="/create-notice" element={<CreateNotice />} />
            <Route path="/departments" element={<DepartmentList />} />
            <Route path="/create-department" element={<CreateDepartment />} />
            <Route
              path="/department-details/:id"
              element={<DepartmentDetails />}
            />
            <Route path="/course" element={<Course />} />
            <Route path="/create-course" element={<CreateCourse />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/create-blog" element={<CreateBlog />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="/create-news" element={<CreateNews />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/create-upload" element={<CreateUpload />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
