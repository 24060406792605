import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaTrash, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Upload = () => {
  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://dpc-backend.onrender.com/downloaddata/all"
        );
        setUploads(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (uploadId) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="custom-confirm-modal">
            <h1>Confirm Deletion</h1>
            <p>Do you want to delete this upload?</p>
            <button
              onClick={async () => {
                try {
                  const response = await axios.delete(
                    `https://dpc-backend.onrender.com/downloaddata/delete/${uploadId}`
                  );

                  if (response.status === 200) {
                    // If the request is successful, update the state
                    setUploads((prevUploads) =>
                      prevUploads.filter((upload) => upload._id !== uploadId)
                    );
                    toast.success("Upload deleted successfully");
                  } else {
                    console.error("Unexpected status code:", response.status);
                    toast.error("Error deleting upload");
                  }
                } catch (error) {
                  console.error("Error deleting upload:", error.message);
                  toast.error("Error deleting upload");
                } finally {
                  onClose();
                }
              }}>
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        ),
      });
    } catch (error) {
      console.error("Error deleting upload:", error);
    }
  };

  return (
    <section>
      <h2>Uploaded Files</h2>
      <div>
        {uploads.length === 0 ? (
          <p>No uploads available.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {uploads.map((upload, index) => (
                <tr key={upload._id}>
                  <td>{index + 1}</td>
                  <td>{upload.title}</td>
                  <td>
                    <button onClick={() => handleDelete(upload._id)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Floating button for creating a new upload */}
      <Link to="/create-upload" className="floating-button">
        <FaPlus />
      </Link>
    </section>
  );
};

export default Upload;
