import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateEvent.css";

function CreateEvent() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const cloudName = "db0wqr9uf";
    const uploadPreset = "dpc@img";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

    try {
      setLoading(true);

      let imageUrl = null;

      if (image) {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("upload_preset", uploadPreset);

        const cloudinaryResponse = await axios.post(cloudinaryUrl, formData);
        console.log("Cloudinary Response:", cloudinaryResponse);
        imageUrl = cloudinaryResponse.data.secure_url;
      }

      const apiEndpoint = "https://dpc-backend.onrender.com/event/create";
      const eventData = {
        title: title,
        description: description,
        imageUrl: imageUrl,
      };
      console.log("Event Data:", eventData);

      const apiResponse = await axios.post(apiEndpoint, eventData);

      console.log("Event created successfully:", apiResponse.data);

      toast.success("Event created successfully");
      setTitle("");
      setDescription("");
      setImage(null);
    } catch (error) {
      console.error("Error creating event:", error);
      toast.error("Error creating event");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section>
      <div className="form-div">
        <h1>Create a New Event</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={handleTitleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              required
            />
          </div>
          <div>
            <label htmlFor="image">Image:</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div>
            <button type="submit" disabled={loading}>
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default CreateEvent;
