import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateCourse.css";

function CreateCourse() {
  const [courseData, setCourseData] = useState({
    name: "",
    description: "",
    fee: "",
    duration: "",
    enrollmentDateFrom: "",
    enrollmentDateTill: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true); // Set loading to true

      // Make POST request to the API
      const response = await axios.post(
        "https://dpc-backend.onrender.com/course/create",
        courseData
      );

      // Notify success
      toast.success("Course created successfully");

      // Clear the form
      setCourseData({
        name: "",
        description: "",
        fee: "",
        duration: "",
        enrollmentDateFrom: "",
        enrollmentDateTill: "",
      });

      console.log("Course created successfully:", response.data);
    } catch (error) {
      // Notify error
      toast.error("Error creating course");
      console.error("Error creating course:", error);
    } finally {
      setLoading(false); // Set loading back to false
    }
  };

  return (
    <section>
      <div className="create-course-div">
        <h2>Create Course</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={courseData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={courseData.description}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="fee">Fee:</label>
            <input
              type="number"
              id="fee"
              name="fee"
              value={courseData.fee}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="duration">Duration:</label>
            <input
              type="text"
              id="duration"
              name="duration"
              value={courseData.duration}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="enrollmentDateFrom">Enrollment Date From:</label>
            <input
              type="date"
              id="enrollmentDateFrom"
              name="enrollmentDateFrom"
              value={courseData.enrollmentDateFrom}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="enrollmentDateTill">Enrollment Date Till:</label>
            <input
              type="date"
              id="enrollmentDateTill"
              name="enrollmentDateTill"
              value={courseData.enrollmentDateTill}
              onChange={handleInputChange}
              required
            />
          </div>

          <button type="submit" disabled={loading}>
            {loading ? "Loading..." : "Create Course"}
          </button>
        </form>
      </div>
    </section>
  );
}

export default CreateCourse;
