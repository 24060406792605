import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./DepartmentDetails.css";

const DepartmentDetails = () => {
  const { id } = useParams();
  const [department, setDepartment] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [selectedHod, setSelectedHod] = useState("");
  const [allStaff, setAllStaff] = useState([]);

  const fetchDepartmentDetails = async () => {
    try {
      const response = await axios.get(
        `https://dpc-backend.onrender.com/department/id/${id}`
      );
      setDepartment(response.data);
      setStaffList(response.data.staffList);

      // Check if HOD is present
      if (!response.data.hod) {
        // If HOD is not present, fetch all staff members
        const staffResponse = await axios.get(
          "https://dpc-backend.onrender.com/staff/all"
        );
        setAllStaff(staffResponse.data);
      }
    } catch (error) {
      console.error("Error fetching department details:", error);
    }
  };

  useEffect(() => {
    fetchDepartmentDetails();
  }, [id]);

  const handleHodChange = (e) => {
    setSelectedHod(e.target.value);
  };

  const handleAddHod = async () => {
    try {
      // Make a patch request to add the selected HOD
      await axios.patch(
        `https://dpc-backend.onrender.com/department/addhod/${selectedHod}`,
        {
          departmentName: department.departmentName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Refresh department details to display the updated HOD
      fetchDepartmentDetails();
    } catch (error) {
      console.error("Error adding HOD:", error);
    }
  };

  return (
    <section>
      <div className="department-details">
        {department ? (
          <>
            <h2>{department.departmentName}</h2>
            <p>{department.description}</p>

            {department.hod ? (
              <p>
                <strong>Head of Department:</strong> {department.hod.name}
              </p>
            ) : (
              <div>
                <p>No Head of Department assigned.</p>
                {allStaff.length > 0 && (
                  <>
                    <label>Select HOD:</label>
                    <select onChange={handleHodChange} value={selectedHod}>
                      <option value="">Select...</option>
                      {allStaff.map((staff) => (
                        <option key={staff._id} value={staff._id}>
                          {staff.name}
                        </option>
                      ))}
                    </select>
                    <button onClick={handleAddHod}>Add HOD</button>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <p>Loading department details...</p>
        )}
      </div>

      <div className="staff-list">
        <h3>Staff List</h3>
        {staffList.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody>
              {staffList.map((staff) => (
                <tr key={staff._id}>
                  <td>{staff.name}</td>
                  <td>{staff.designation}</td>
                  {/* Add more staff details as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No staff assigned to this department.</p>
        )}
      </div>
    </section>
  );
};

export default DepartmentDetails;
