import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateBlog.css";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = "";

      if (image) {
        setLoading(true);

        // Upload image to Cloudinary
        const formData = new FormData();
        formData.append("file", image);
        formData.append("upload_preset", "dpc@img");

        const cloudinaryResponse = await axios.post(
          "https://api.cloudinary.com/v1_1/db0wqr9uf/image/upload",
          formData
        );

        imageUrl = cloudinaryResponse.data.secure_url;
      }

      // Make a POST request to the API
      await axios.post("https://dpc-backend.onrender.com/blog/create", {
        title,
        content,
        category,
        imageUrl,
      });

      setLoading(false);

      toast.success("Blog created successfully");
    } catch (error) {
      setLoading(false);

      toast.error("Error creating blog");
      console.error("Error creating blog:", error);
    }
  };

  return (
    <section>
      <div className="form-div">
        <h2>Create Blog</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Title:
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </label>

          <label>
            Content:
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            />
          </label>

          <label>
            Category:
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required>
              <option value="">Select Category</option>
              <option value="Sports">Sports</option>
              <option value="NSS">NSS</option>
              <option value="Environment">Environment</option>
            </select>
          </label>

          <label>
            Image (optional):
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </label>

          <button type="submit" disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateBlog;
