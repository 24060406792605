// PrivateRoute.js
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute({ element, ...rest }) {
  // console.log(userid, officeid,"haooooooooooooooo")
  const auth = localStorage.getItem("token");

  return auth ? <Outlet /> : <Navigate to={"/login"} />;
}

export default PrivateRoute;
