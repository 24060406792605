import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Course.css";

function Course() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch("https://dpc-backend.onrender.com/course/all")
      .then((response) => response.json())
      .then((data) => setCourses(data))
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  const handleDelete = (courseId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-modal">
            <h1>Confirm Deletion</h1>
            <p>Do you want to delete this course?</p>
            <button
              onClick={() => {
                // Call API to delete course
                fetch(
                  `https://dpc-backend.onrender.com/course/delete/${courseId}`,
                  {
                    method: "DELETE",
                  }
                )
                  .then(() => {
                    // Remove course from state
                    setCourses((prevCourses) =>
                      prevCourses.filter((course) => course._id !== courseId)
                    );
                    onClose();
                  })
                  .catch((error) =>
                    console.error("Error deleting course:", error)
                  );
              }}>
              Yes
            </button>
            <button onClick={onClose}>Cancel</button>
          </div>
        );
      },
    });
  };

  return (
    <section className="course-section" id="course-section">
      <h1 className="heading">Courses we offer</h1>
      <div className="course-container">
        {courses.map((course) => (
          <div key={course._id} className="course">
            <button
              className="delete-icon"
              onClick={() => handleDelete(course._id)}>
              <FaTrash />
            </button>
            <h2>{course.name}</h2>
            <p>{course.description}</p>
            <p>
              <strong>Fee</strong>: Rs. {course.fee}
            </p>
            <p>
              <strong>Duration</strong>: {course.duration}
            </p>
            <p>
              <strong>Enrollment Period</strong>:{" "}
              {new Date(course.enrollmentDateFrom).toLocaleDateString()} to{" "}
              {new Date(course.enrollmentDateTill).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
      <Link to="/create-course" className="create-course">
        <FaPlus /> Add Course
      </Link>
    </section>
  );
}

export default Course;
