import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateNews = () => {
  const [news, setNews] = useState("");
  const [newsUrl, setNewsUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateNews = async () => {
    try {
      // Set loading state to true
      setIsLoading(true);

      // Make POST request to the API
      await axios.post("https://dpc-backend.onrender.com/notification/create", {
        news,
        newsUrl,
      });

      // Notify success using React Toastify
      toast.success("News created successfully");
    } catch (error) {
      console.error("Error creating news:", error);
    } finally {
      // Set loading state back to false after the request completes
      setIsLoading(false);
    }
  };

  return (
    <section>
      <div className="form-div">
        <h2>Create News</h2>
        <form>
          <label>
            News:
            <input
              type="text"
              value={news}
              onChange={(e) => setNews(e.target.value)}
            />
          </label>
          <br />
          <label>
            News URL:
            <input
              type="text"
              value={newsUrl}
              onChange={(e) => setNewsUrl(e.target.value)}
            />
          </label>
          <br />
          <button type="button" onClick={handleCreateNews} disabled={isLoading}>
            {isLoading ? "Loading..." : "Create News"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateNews;
