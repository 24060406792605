import React, { useState } from "react";
import axios from "axios";
import "./Signup.css";
import { Link } from "react-router-dom";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registrationDone, setRegistrationDone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSignup = async () => {
    try {
      await axios.post("https://dpc-backend.onrender.com/user/auth/register", {
        name,
        email,
        password,
      });

      setRegistrationDone(true);
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  return (
    <section className="signup-section">
      <div className="signup-div">
        {registrationDone ? (
          <div className="message-box">
            <p>
              Thank you for registering. Please wait for approval.
              <Link to="/login"> Login</Link>
            </p>
          </div>
        ) : (
          <>
            <h2>SIGN UP</h2>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <label>
              Password:
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="check">
                <input
                  className="show-pass"
                  type="checkbox"
                  checked={showPassword}
                  onChange={() => setShowPassword(!showPassword)}
                />
                Show Password
              </div>
            </label>
            <button onClick={handleSignup}>Sign Up</button>
            <p>
              Already have an account?
              <Link to="/login">Login here.</Link>
            </p>
          </>
        )}
      </div>
    </section>
  );
};

export default Signup;
