import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Blog.css";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get("https://dpc-backend.onrender.com/blog/all")
      .then((response) => {
        setBlogs(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
      });
  }, []);

  const handleDelete = async (blogId) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="custom-confirm-modal">
            <h1>Confirm Deletion</h1>
            <p>Do you want to delete this blog?</p>
            <button
              onClick={async () => {
                await axios.delete(
                  `https://dpc-backend.onrender.com/blog/delete/${blogId}`
                );

                setBlogs((prevBlogs) =>
                  prevBlogs.filter((blog) => blog._id !== blogId)
                );

                toast.success("Blog deleted successfully");
                onClose();
              }}>
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        ),
      });
    } catch (error) {
      toast.error("Error deleting blog");
      console.error("Error deleting blog:", error);
    }
  };

  return (
    <section>
      <div>
        <h1>Blogs</h1>
        <table>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Title</th>
              <th>Content</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog, index) => (
              <tr key={blog._id}>
                <td>{index + 1}</td>
                <td>{blog.title}</td>
                <td>{blog.content}</td>
                <td>{blog.category}</td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDelete(blog._id)}>
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Floating button for creating a new blog */}
        <Link to="/create-blog" className="floating-button">
          <FaPlus />
        </Link>
      </div>
    </section>
  );
};

export default Blog;
