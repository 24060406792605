import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateStaff.css";

const CreateStaff = () => {
  const [departments, setDepartments] = useState([]);
  const [staffData, setStaffData] = useState({
    name: "",
    fatherName: "",
    designation: "",
    category: "",
    dob: "",
    bloodGroup: "",
    appointmentDate: "",
    email: "",
    phone: "",
    address: "",
    imageFile: null,
    department: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get("https://dpc-backend.onrender.com/department/all")
      .then((response) => {
        console.log("API Response:", response.data);
        setDepartments(response.data.data || []);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
        setDepartments([]);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setStaffData({ ...staffData, imageFile: files[0] });
    } else {
      setStaffData({ ...staffData, [name]: value });
    }
  };

  const handleImageUpload = async () => {
    const cloudName = "db0wqr9uf";
    const uploadPreset = "dpc@img";
    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

    const formData = new FormData();
    formData.append("file", staffData.imageFile);
    formData.append("upload_preset", uploadPreset);

    try {
      const response = await axios.post(cloudinaryUrl, formData);
      setStaffData({ ...staffData, imageUrl: response.data.secure_url });
    } catch (error) {
      console.error("Error uploading image to Cloudinary:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Set loading to true
    setIsLoading(true);

    try {
      await handleImageUpload();

      const response = await axios.post(
        "https://dpc-backend.onrender.com/staff/create",
        staffData
      );

      // Notify success, reset form, and set loading to false
      toast.success("Staff created successfully");
      setStaffData({
        name: "",
        fatherName: "",
        designation: "",
        category: "",
        dob: "",
        bloodGroup: "",
        appointmentDate: "",
        email: "",
        phone: "",
        address: "",
        imageFile: null,
        department: "",
      });
      setIsLoading(false);

      console.log("Staff member created successfully:", response.data);
    } catch (error) {
      // Notify error, set loading to false
      toast.error("Error creating staff member");
      setIsLoading(false);
      console.error("Error creating staff member:", error);
    }
  };

  return (
    <section>
      {" "}
      <div className="staff-list-div">
        <h2>Create Staff</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={staffData.name}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Father's Name:
            <input
              type="text"
              name="fatherName"
              value={staffData.fatherName}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Designation:
            <input
              type="text"
              name="designation"
              value={staffData.designation}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Category:
            <input
              type="text"
              name="category"
              value={staffData.category}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Date of Birth:
            <input
              type="date"
              name="dob"
              value={staffData.dob}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Blood Group:
            <input
              type="text"
              name="bloodGroup"
              value={staffData.bloodGroup}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Appointment Date:
            <input
              type="date"
              name="appointmentDate"
              value={staffData.appointmentDate}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Email:
            <input
              type="email"
              name="email"
              value={staffData.email}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Phone:
            <input
              type="tel"
              name="phone"
              value={staffData.phone}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Address:
            <input
              type="text"
              name="address"
              value={staffData.address}
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Image:
            <input type="file" name="imageFile" onChange={handleInputChange} />
          </label>

          <label>
            Department:
            <select
              name="department"
              value={staffData.department}
              onChange={handleInputChange}>
              <option value="">Select Department</option>
              {departments.map((department) => (
                <option key={department._id} value={department._id}>
                  {department.departmentName}
                </option>
              ))}
            </select>
          </label>

          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Create Staff"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateStaff;
