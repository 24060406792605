import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./EventList.css";
import { FaPlus, FaTrash } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const EventList = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://dpc-backend.onrender.com/event/all"
        );

        // Sort events based on createdDateTime in descending order
        const sortedEvents = response.data.sort(
          (a, b) => new Date(b.createDateTime) - new Date(a.createDateTime)
        );

        setEvents(sortedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleDelete = (eventId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-modal">
            <h1>Confirm Deletion</h1>
            <p>Do you want to delete this event?</p>
            <button
              onClick={async () => {
                try {
                  // Make API call to delete the event
                  await axios.delete(
                    `https://dpc-backend.onrender.com/event/delete/${eventId}`
                  );

                  // Update state to remove the deleted event
                  setEvents((prevEvents) =>
                    prevEvents.filter((event) => event._id !== eventId)
                  );

                  onClose();
                } catch (error) {
                  console.error(
                    `Error deleting event with ID: ${eventId}`,
                    error
                  );
                }
              }}>
              Yes
            </button>
            <button onClick={onClose}>Cancel</button>
          </div>
        );
      },
    });
  };

  return (
    <section>
      <div>
        {events.map((event) => (
          <div key={event._id} className="event-container">
            <div className="event-div">
              <div className="event-header">
                <p>{new Date(event.createDateTime).toLocaleString()}</p>
                <h3>{event.title}</h3>
                <FaTrash
                  className="delete-icon"
                  onClick={() => handleDelete(event._id)}
                />
              </div>

              {event.imageUrl && (
                <div className="image-container">
                  <img src={event.imageUrl} alt={event.title} />
                </div>
              )}
              <p>
                <i>{event.description}</i>
              </p>
            </div>
          </div>
        ))}

        <Link to="/create-event" className="floating-button">
          <FaPlus />
        </Link>
      </div>
    </section>
  );
};

export default EventList;
