import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./DepartmentList.css";
import { FaPlus, FaTrash } from "react-icons/fa";

const DepartmentList = () => {
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://dpc-backend.onrender.com/department/all"
        );

        setDepartmentList(response.data.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (departmentId) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-modal">
              <h1>Confirm Deletion</h1>
              <p>Do you want to delete this department?</p>
              <button
                onClick={async () => {
                  await axios.delete(
                    `https://dpc-backend.onrender.com/department/delete/${departmentId}`
                  );
                  setDepartmentList((prevDepartmentList) =>
                    prevDepartmentList.filter(
                      (department) => department._id !== departmentId
                    )
                  );
                  onClose();
                }}>
                Yes
              </button>
              <button onClick={onClose}>Cancel</button>
            </div>
          );
        },
      });
    } catch (error) {
      console.error(
        `Error deleting department with ID: ${departmentId}`,
        error
      );
    }
  };

  return (
    <section>
      <div className="table-container">
        <h2>Department List</h2>
        {departmentList.length === 0 ? (
          <p>No departments available. Please add one.</p>
        ) : (
          <table className="department-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Head of Department</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {departmentList.map((department, index) => (
                <tr key={department._id}>
                  <td>
                    <Link to={`/department-details/${department._id}`}>
                      {department.departmentName}
                    </Link>
                  </td>
                  <td>
                    {department.hod ? department.hod.name : "Not Assigned"}
                  </td>
                  <td>
                    <button
                      className="delete"
                      onClick={() => handleDelete(department._id)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Floating button for creating a new department */}
        <Link to="/create-department" className="floating-button">
          <FaPlus />
        </Link>
      </div>
    </section>
  );
};

export default DepartmentList;
