import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./StaffList.css"; // Import the CSS file
import { FaPlus, FaTrash } from "react-icons/fa";

const StaffList = () => {
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://dpc-backend.onrender.com/staff/all"
        );
        setStaffList(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching staff members:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (staffId) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-modal">
              <h1>Confirm Deletion</h1>
              <p>Do you want to delete this staff member?</p>
              <button
                onClick={async () => {
                  await axios.delete(
                    `https://dpc-backend.onrender.com/staff/delete/${staffId}`
                  );
                  setStaffList((prevStaffList) =>
                    prevStaffList.filter((staff) => staff._id !== staffId)
                  );
                  onClose();
                }}>
                Yes
              </button>
              <button onClick={onClose}>Cancel</button>
            </div>
          );
        },
      });
    } catch (error) {
      console.error(`Error deleting staff with ID: ${staffId}`, error);
    }
  };

  return (
    <section>
      {" "}
      <div className="table-container">
        <h2>Staff List</h2>
        {staffList.length === 0 ? (
          <p>No staff members available. Please add one.</p>
        ) : (
          <table className="staff-table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {staffList.map((staff, index) => (
                <tr key={staff._id}>
                  <td>{index + 1}</td>
                  <td>{staff.name}</td>
                  <td>{staff.email}</td>
                  <td>{staff.designation}</td>
                  <td>
                    {staff.department
                      ? staff.department.departmentName
                      : "Not in any department"}
                  </td>
                  <td>
                    <button
                      className="delete"
                      onClick={() => handleDelete(staff._id)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Floating button for creating a new staff */}
        <Link to="/create-staff" className="floating-button">
          <FaPlus />
        </Link>
      </div>
    </section>
  );
};

export default StaffList;
