import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://dpc-backend.onrender.com/user/auth/login",
        {
          email,
          password,
        }
      );

      const { token } = response.data;

      localStorage.setItem("token", token);

      toast.success("Welcome to the admin panel!");

      navigate("/");
    } catch (error) {
      console.log(error.message);
      toast.error("Login failed. Please check your credentials.");
    }
  };

  return (
    <section className="login-section">
      <div className="login-div">
        <h2>LOGIN</h2>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Password:
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="check">
            <input
              className="show-pass"
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            Show Password
          </div>
        </label>
        <button onClick={handleLogin}>Login</button>
        <p>
          Don't have an account?
          <Link to="/signup">Register here.</Link>
        </p>
      </div>
    </section>
  );
};

export default Login;
