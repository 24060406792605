import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import {
  FaHome,
  FaBook,
  FaCalendarAlt,
  FaImage,
  FaUsers,
  FaBuilding,
  FaNewspaper,
  FaFilePdf,
} from "react-icons/fa";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <nav>
      <div className="logo-container">
        <img
          src={`${process.env.PUBLIC_URL}/logoDPC.png`}
          alt="Logo"
          className="logo"
        />
      </div>
      <ul>
        <li>
          <NavLink to="/" exact activeClassName="active">
            <FaHome /> Home
          </NavLink>
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink to="course-section" smooth={true} duration={500}>
              <FaBook /> Course
            </ScrollLink>
          ) : (
            <NavLink to="/course" activeClassName="active">
              <FaBook /> Course
            </NavLink>
          )}
        </li>
        <li>
          <NavLink to="/blog" activeClassName="active">
            <FaBook /> Blog
          </NavLink>
        </li>
        <li>
          <NavLink to="/event" activeClassName="active">
            <FaCalendarAlt /> Event
          </NavLink>
        </li>
        <li>
          <NavLink to="/gallery" activeClassName="active">
            <FaImage /> Gallery
          </NavLink>
        </li>
        <li>
          <NavLink to="/staff-list" activeClassName="active">
            <FaUsers /> Staff
          </NavLink>
        </li>
        <li>
          <NavLink to="/departments" activeClassName="active">
            <FaBuilding /> Departments
          </NavLink>
        </li>
        <li>
          <NavLink to="/news" activeClassName="active">
            <FaNewspaper /> News
          </NavLink>
        </li>
        <li>
          <NavLink to="/notice" activeClassName="active">
            <FaNewspaper /> Notices
          </NavLink>
        </li>
        <li>
          <NavLink to="/upload" activeClassName="active">
            <FaFilePdf /> Uploads
          </NavLink>
        </li>
        <li>
          <div className="logout-div">
            <buttom className="logout" onClick={() => handleLogout()}>
              Logout
            </buttom>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
