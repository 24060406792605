import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateDepartment() {
  const [departmentName, setDepartmentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://dpc-backend.onrender.com/department/create",
        {
          departmentName: departmentName,
        }
      );

      // Handle the response, e.g., show a success message or redirect to another page
      console.log("Department created successfully:", response.data);
      toast.success("Department created successfully");
      // Optionally, you can clear the input fields after successful submission
      setDepartmentName("");
    } catch (error) {
      console.error("Error creating department:", error.message);
      toast.error("Error creating department");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <div className="form-div">
        <h2>Create Department</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Department Name:
            <input
              type="text"
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
              required
            />
          </label>

          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </section>
  );
}

export default CreateDepartment;
