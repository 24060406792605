import React from "react";

import "./Home.css";
import Course from "../CoursePages/Course";

function Home() {
  return (
    <div>
      <header className="hero">
        <div className="text-box">
          <h1>D.P.Chaturvedi</h1>
          <p>
            The D.P. Chaturvedi College in Seoni offers a wide <br />
            range of academic disciplines, including Science, <br />
            Commerce, Arts, and Education. It has played a pivotal role in
            establishing a benchmark for brexcellence in education.
          </p>
        </div>
      </header>
      <Course />
    </div>
  );
}

export default Home;
