// Import necessary dependencies from React and React Router
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { FaTimes, FaPlus, FaTrash } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Gallery.css";

const Gallery = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    axios
      .get("https://dpc-backend.onrender.com/post/all")
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
      });
  }, []);

  const openModal = (post) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const handleDelete = (postId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-confirm-modal">
            <h1>Confirm Deletion</h1>
            <p>Do you want to delete this post?</p>
            <button
              onClick={async () => {
                try {
                  await axios.delete(
                    `https://dpc-backend.onrender.com/post/delete/${postId}`
                  );
                  setPosts((prevPosts) =>
                    prevPosts.filter((post) => post._id !== postId)
                  );
                  onClose();
                } catch (error) {
                  console.error("Error deleting post:", error);
                }
              }}>
              Yes
            </button>
            <button onClick={onClose}>Cancel</button>
          </div>
        );
      },
    });
  };

  return (
    <section>
      {" "}
      <div className="main-div">
        <div className="gallery">
          {posts.map((post) => (
            <div key={post._id} className="image-container">
              <img
                src={post.imageUrl}
                alt={post.caption}
                onClick={() => openModal(post)}
              />
              <button
                className="delete-icon"
                onClick={() => handleDelete(post._id)}>
                <FaTrash />
              </button>
            </div>
          ))}

          <Modal
            isOpen={selectedPost !== null}
            onRequestClose={closeModal}
            contentLabel="Image Modal"
            className="modal"
            overlayClassName="modal-overlay">
            <div className="modal-content">
              <img
                src={selectedPost?.imageUrl}
                alt={selectedPost?.caption}
                className="modal-image"
              />
              <div className="modal-details">
                <p className="caption">{selectedPost?.caption}</p>
                <p className="created-time">
                  Posted on:{" "}
                  {new Date(selectedPost?.createDateTime).toLocaleString()}
                </p>
              </div>
              <button className="close-modal" onClick={closeModal}>
                <FaTimes />
              </button>
            </div>
          </Modal>
        </div>

        {/* Floating button for creating a new post */}
        <Link to="/create-post" className="floating-button">
          <FaPlus />
        </Link>
      </div>
    </section>
  );
};

export default Gallery;
