import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateNotice = () => {
  const [notice, setNotice] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNoticeChange = (e) => {
    setNotice(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post("https://dpc-backend.onrender.com/notice/create", {
        notice,
      });

      setLoading(false);

      toast.success("Notice created successfully");
    } catch (error) {
      setLoading(false);

      toast.error("Error creating notice");
      console.error("Error creating notice:", error);
    }
  };

  return (
    <section>
      <div className="form-div">
        <h2>Create Notice</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Notice:
            <input
              type="text"
              value={notice}
              onChange={handleNoticeChange}
              required
            />
          </label>

          <button type="submit" disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateNotice;
