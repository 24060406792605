import React, { useEffect, useState } from "react";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaTrash, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./Notices.css";

const NoticeList = () => {
  const [noticeList, setNoticeList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://dpc-backend.onrender.com/notice/all"
        );
        setNoticeList(response.data);
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };

    fetchData();
  }, []);

  const formatHumanReadableDate = (isoDate) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(isoDate).toLocaleDateString("en-US", options);
  };

  const handleDelete = async (noticeId) => {
    try {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-confirm-modal">
              <h1>Confirm Deletion</h1>
              <p>Do you want to delete this notice?</p>
              <button
                onClick={async () => {
                  await axios.delete(
                    `https://dpc-backend.onrender.com/notice/delete/${noticeId}`
                  );
                  setNoticeList((prevNoticeList) =>
                    prevNoticeList.filter((notice) => notice._id !== noticeId)
                  );
                  onClose();
                }}>
                Yes
              </button>
              <button onClick={onClose}>Cancel</button>
            </div>
          );
        },
      });
    } catch (error) {
      console.error(`Error deleting notice with ID: ${noticeId}`, error);
    }
  };

  return (
    <section>
      {" "}
      <div className="table-container">
        <h2>Notice List</h2>
        {noticeList.length === 0 ? (
          <p>No notices available.</p>
        ) : (
          <React.Fragment>
            <table className="notice-table">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Notice</th>
                  <th>Created Date Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {noticeList.map((notice, index) => (
                  <tr key={notice._id}>
                    <td>{index + 1}</td>
                    <td>{notice.notice}</td>
                    <td>{formatHumanReadableDate(notice.createdDateTime)}</td>
                    <td>
                      <button
                        className="delete"
                        onClick={() => handleDelete(notice._id)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Floating button for creating a new notice */}
          </React.Fragment>
        )}
      </div>
      <Link to="/create-notice" className="floating-button">
        <FaPlus />
      </Link>
    </section>
  );
};

export default NoticeList;
